<template>
  <v-container fluid class="mt-0 pt-0">
    <loader
      v-bind:isVisible="$store.getters.getGutachtenDetailOverlay"
    ></loader>
    <v-row class="pl-5">
      <v-col cols="auto" class="pl-0 pr-0 pt-0">
        <v-sheet rounded>
          <v-text-field
            class="pt-3 pl-3 pr-3 pb-3"
            dense
            solo-inverted
            hide-details
            label="Suche in Aufträgen..."
            v-model="search"
          ></v-text-field>

          <v-list color="transparent">
            <v-list-item-group ref="menue_items">
              <template v-for="auftrag in filterAuftraege">
                <v-list-item
                  @click="fetchGutachtenDetails(auftrag.id)"
                  class="auftrag"
                  :key="auftrag.id"
                >
                  <v-row justify="center" no-gutters>
                    <v-col cols="12" align="center">
                      <v-divider style="max-width:60%;margin:0"></v-divider>
                    </v-col>
                    <v-col style="display: flex">
                      <div class="pb-2 pt-2">
                        <v-list-item-title>{{
                          auftrag.client_name
                        }}</v-list-item-title>
                        <v-list-item-subtitle class="pt-1">{{
                          auftrag.partner_name
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="pt-1">
                          {{
                            getTimeDifference(
                              auftrag.create_tst,
                              auftrag.antrag_typ.toLowerCase()
                            )
                          }}
                        </v-list-item-subtitle>
                      </div>
                      <v-spacer class="mr-5"></v-spacer>
                      <v-icon
                        v-if="auftrag.antrag_typ === 'express'"
                        color="red"
                        size="26px"
                        style="font-weight: bold;"
                        >E</v-icon
                      >
                      <v-icon
                        v-else
                        color="green"
                        size="26px"
                        style="font-weight: bold;"
                        >N</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-list-item>
              </template>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>

            <v-list-item link color="grey lighten-4">
              <v-row justify="center">
                <v-col cols="1" class="ma-0 pa-0 mr-2">
                  <v-icon class="mt-2"> mdi-refresh </v-icon>
                </v-col>
                <v-col cols="auto" class="ma-0 pa-0">
                  <v-list-item-content>
                    <v-list-item-title @click="fetchGutachtenListe()"
                      >Aktualisieren</v-list-item-title
                    >
                  </v-list-item-content>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col class="pt-0">
        <AuftragDetail
          v-bind:currentStage="stage_name"
          @reload-gutachten-list="fetchGutachtenListe()"
        ></AuftragDetail>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuftragDetail from "../components/AuftragDetail";
import loader from "../components/Loader";

import { mapGetters } from "vuex";
import dateCalculations from "../services/dateCalculations";

export default {
  name: "Uebersicht",
  data: () => ({
    search: "",
    stage_name: "qs_tech",
    gutachtenListe: []
  }),
  components: {
    AuftragDetail,
    loader
  },
  created() {
    clearInterval(window.oaTrigger);
    console.log("set Interval-Trigger OQST");
    window.oaTrigger = setInterval(async () => {
      await this.fetchGutachtenListe();
    }, 10000);
  },
  methods: {
    getTimeDifference(auftragTimestamp, antragsTyp) {
      return dateCalculations.getTimeDifference(
        auftragTimestamp,
        antragsTyp,
        this.$store
      )[0];
    },
    async fetchGutachtenListe() {
      console.log("event trigger - fetch list OQST");
      this.gutachtenListe = await this.$store.dispatch(
        "fetchGutachtenListe",
        this.stage_name
      );
    },
    async fetchGutachtenDetails(id) {
      this.$store.commit("setGutachtenDetailOverlay", true);
      await this.$store.dispatch("fetchGutachtenDetails", id);
      this.$store.commit("setGutachtenDetailOverlay", false);
    }
  },
  computed: {
    ...mapGetters(["getGutachtenDetails"]),
    filterAuftraege() {
      const filtered = this.gutachtenListe;
      return filtered
        .sort((a, b) => {
          return Number(
            dateCalculations.getTimeDifference(
              a.create_tst,
              a.antrag_typ.toLowerCase(),
              this.$store
            )[1]
          ) >
            Number(
              dateCalculations.getTimeDifference(
                b.create_tst,
                b.antrag_typ.toLowerCase(),
                this.$store
              )[1]
            )
            ? 1
            : -1;
        })
        .filter(item => {
          if (!this.search) return this.gutachtenListe;
          return (
            item.partner_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.client_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.kennzeichen
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.schadennummer
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.vorgangsnummer
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
    }
  },
  async mounted() {
    await this.fetchGutachtenListe();
    this.$store.commit("setMainContextName", "Übersicht - QS-Tech");
  }
};
</script>

<style>
.auftrag {
  cursor: pointer;
}
.auftrag:hover {
  background-color: lightgray;
}
</style>
